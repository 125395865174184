import React from 'react';
import Link from 'components/link';
import { translate } from 'utils/translations';
import { buildClassName } from 'utils/build-class-name';
import NibIcon from 'components/icon/nib-icon';
import styles from './style.module.scss';
type NestedDropdownProps = {
item: any;
index: number;
expandedItems: { [key: string]: boolean };
toggleExpand: (id: string) => void;
siteLocation: any;
};
export default function NestedDropdown({
  item,
  index,
  expandedItems,
  toggleExpand,
  siteLocation,
}: NestedDropdownProps) {
  if (item.nested && Array.isArray(item.nested) && item.nested.length > 0) {
    return (
      <li key={index}>
        <div className={buildClassName(styles['nested-item-header'], expandedItems[item.id] && styles.active)}>
          <div className={styles['nested-header-content']}>
            {item.link ? (
              <Link
                href={item.link}
                target={item.newTab ? '_blank' : '_self'}
                onClick={() => toggleExpand(item.id)}
                data-testid={`${item.id}main`}
              >
                {translate(item.label, siteLocation)}
              </Link>
            ) : (
              <span data-testid={`${item.id}main`} onClick={() => toggleExpand(item.id)} className={styles['nested-header']}>
                {translate(item.label, siteLocation)}
              </span>
            )}
            <NibIcon className={styles['nested-arrow']} onClick={() => toggleExpand(item.id)} />
          </div>
        </div>
        {expandedItems[item.id] && (
          <ul className={styles['nested-list']}>
            {item.nested.map((nestedItem, idx) => (
              <li key={idx}>
                {nestedItem.link ? (
                  <Link
                    href={nestedItem.link}
                    target={nestedItem.newTab ? '_blank' : '_self'}
                    data-testid={`${nestedItem.id}sub`}
                  >
                    {translate(nestedItem.label, siteLocation)}
                  </Link>
                ) : (
                  <span data-testid={`${nestedItem.id}sub`}>
                    {translate(nestedItem.label, siteLocation)}
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  }
}